import React from "react";
import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

import { Icon } from '@types';

const Logo: Icon = ({ fill = "white" }) => {
  return (
    <LogoContainer>
    <svg
      width="36"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="Logo__Desktop"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M31.2529776,92 C40.7813244,92 48.4040019,89.2005533 54.12101,83.4744122 C59.8380181,77.7482711 62.6329998,70.2406639 62.6329998,60.6970954 L62.6329998,0 L44.0844847,0 L44.0844847,61.2060858 C44.0844847,69.9861687 39.5108782,75.5850622 31.2529776,75.5850622 C23.5032555,75.5850622 18.5485152,70.8769018 18.5485152,62.7330567 C18.5485152,60.1881051 18.6755598,58.1521438 18.929649,56.6251729 L0.381133873,56.6251729 L0,62.7330567 C0,71.8948824 2.92202636,79.0207469 8.63903446,84.2378976 C14.3560426,89.4550484 21.8516754,92 31.2529776,92 Z M87.8037161,91.3637621 C91.3609655,91.3637621 94.2829919,90.2185339 96.5697951,87.9280775 C98.8565984,85.7648686 100,82.8381743 100,79.275242 C100,72.2766252 94.7911704,67.0594744 87.8037161,67.0594744 C80.6892171,67.0594744 75.6074321,72.2766252 75.6074321,79.275242 C75.6074321,86.4011065 80.6892171,91.3637621 87.8037161,91.3637621 Z"
          fill={fill}
        />

      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>

    <svg
      width="28"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="Logo__Mobile"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M31.2529776,92 C40.7813244,92 48.4040019,89.2005533 54.12101,83.4744122 C59.8380181,77.7482711 62.6329998,70.2406639 62.6329998,60.6970954 L62.6329998,0 L44.0844847,0 L44.0844847,61.2060858 C44.0844847,69.9861687 39.5108782,75.5850622 31.2529776,75.5850622 C23.5032555,75.5850622 18.5485152,70.8769018 18.5485152,62.7330567 C18.5485152,60.1881051 18.6755598,58.1521438 18.929649,56.6251729 L0.381133873,56.6251729 L0,62.7330567 C0,71.8948824 2.92202636,79.0207469 8.63903446,84.2378976 C14.3560426,89.4550484 21.8516754,92 31.2529776,92 Z M87.8037161,91.3637621 C91.3609655,91.3637621 94.2829919,90.2185339 96.5697951,87.9280775 C98.8565984,85.7648686 100,82.8381743 100,79.275242 C100,72.2766252 94.7911704,67.0594744 87.8037161,67.0594744 C80.6892171,67.0594744 75.6074321,72.2766252 75.6074321,79.275242 C75.6074321,86.4011065 80.6892171,91.3637621 87.8037161,91.3637621 Z"
          fill={fill}
        />

      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="100" height="100" fill="white" />
        </clipPath>
      </defs>
    </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }

    .Logo__Mobile{
      display: block;
    }
  `}
`;
